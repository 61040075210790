<template>
  <div>
    <el-row>
      <el-col :span="4">
        <div class="area-tree">
          <div class="hd">选择校区</div>
          <el-tree :data="dataTreeB" :props="defaultPropsB" @node-click="handleClick" default-expand-all></el-tree>
        </div>
      </el-col>
      <el-col :span="20">
        <!-- 宿舍管理 -->
        <div class="content-wrap">
          <div class="btn-wrap cfx">
            <el-button type="success" size="small" icon="el-icon-plus" @click="form.level = 1, handleForm(false)" v-if="OA.includes('jcxx:ssgl:addFrist')">添加楼栋</el-button>
          </div>
          
          <div v-if="apartmentList.length==0" class="nothing"><img src="../../assets/images/nothing.svg" alt=""></div>
          <el-table type="expand" row-key="id" :expand-row-keys="expands" border :data="apartmentList" v-loading="tLoading" :tree-props="{ children: 'children' }" @row-click="clickRowHandle">
          <!-- <el-table v-if="apartmentList.length>0" :expand-row-keys="expands" border :data="apartmentList" v-loading="tLoading" row-key="id" :tree-props="{ children: 'children' }"> -->
            <el-table-column :show-overflow-tooltip="true" prop="name" label="楼栋">
              <template slot-scope="scope">
                <span v-if="scope.row.level == 1">{{scope.row.name + "#"}}</span>
                <span v-else-if="scope.row.level == 2">{{scope.row.name + "F"}}</span>
                <span v-else>{{scope.row.name}}</span>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="pid" label="分类等级">
              <template slot-scope="scope">
                <span v-if="scope.row.level == 1">一级分类</span> 
                <span v-if="scope.row.level == 2">二级分类</span> 
                <span v-if="scope.row.level == 3">三级分类</span> 
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="path" label="分类属性">
              <template slot-scope="scope">
                <div v-if="scope.row.level ==1">
                  <span v-if="scope.row.type == 0">女生宿舍</span>   
                  <span v-if="scope.row.type == 1">男生宿舍</span>
                  <span v-if="scope.row.type == 2">混合宿舍</span>
                </div>
                <div v-else>- - -</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="140" align="right">
              <template slot-scope="scope">
                <el-button v-if="OA.includes('jcxx:ssgl:add') && scope.row.level<3" size="mini" type="success" title="新增" @click="handleForm(false, scope.row,afterHandleForm)" icon="el-icon-plus"></el-button>
                <el-button v-if="OA.includes('jcxx:ssgl:editor') && scope.row.level<3" type="primary" size="mini" title="编辑" @click="handleForm(true, scope.row,afterEditorForm)" icon="el-icon-edit"></el-button>
                <el-button v-if="OA.includes('jcxx:ssgl:del')" type="danger" size="mini" title="删除" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑宿舍' : '添加宿舍'" @close="resetDetail()" :visible.sync="aVisible" width="600px" :close-on-click-modal="false">
      <el-form label-width="100px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="学校名称：" prop="distId">
          <el-cascader v-if="!distAdress" class="w350" :disabled="form.level!=1 || (isEditor &&form.level!=1)" placeholder="请选择校区名称" v-model="value" :options="dataTreeB" accordion :props="{ checkStrictly: true, ...defaultPropsB }" @change="handleNodeClick" clearable ref="cascader"></el-cascader>
          <el-input class="w350" v-else :disabled="true" clearable size="small" v-model="distAdress"></el-input>
        </el-form-item>
        <el-form-item label="楼栋名称：" prop="name">
          <el-input v-if="!editDetail.name" class="w350" :disabled="form.level!=1 || (isEditor &&form.level!=1)" clearable size="small" v-model="form.name" placeholder="请输入楼栋"></el-input>
          <el-input class="w350" v-else :disabled="true" clearable size="small" v-model="editDetail.name"></el-input>
        </el-form-item>
        <el-form-item label="楼栋属性：" prop="type">
          <el-radio-group :disabled="form.level!=1 || (isEditor &&form.level!=1)" v-model="form.type">
            <el-radio label="1">男生宿舍</el-radio>
            <el-radio label="0">女生宿舍</el-radio>
            <el-radio label="2">混合宿舍</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="楼层：" prop="nameFloor" v-if="form.level != 1 || (isEditor &&form.level!=1)">
          <el-input class="w350" v-if="!editDetail.name" placeholder="请输入楼层" clearable size="small" v-model="form.nameFloor"></el-input>
          <el-input class="w350" v-else clearable size="small" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="宿舍号：" prop="nameRoom" v-if="form.level ==3" class="nameRoom">
          <div v-for="(item,index) in nameRoomArr" :key="index">
            <el-input style="width:310px" placeholder="请输入宿舍号" v-model="nameRoomArr[index]" @change="handleSketch"></el-input>
            <img src="@/assets/images/operate/add.svg" alt class="btn" v-if="index == 0" @click="addTeam" />
            <img src="@/assets/images/operate/delete.svg" alt class="btn" v-if="index > 0" @click="removeTeam(index)" />
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false, resetDetail()">取消</el-button>
        <el-button
          size="small"
          type="primary"
          :loading="bLoading"
          @click="isEditor ? submitForm(editorApi,formEditChange,resetDetail) : submitForm(insertApi,formChange,resetDetail)"
          >保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from "@/common/utils";
export default {
  name: "apartmentManagement",
  components: {
    PagedTable,
  },
  mixins: [pageMixin],
  data() {
    var validateName = (rule, value, callback) => {
      if (this.form.name == '') {
        callback(new Error('信息不全,无法添加'));
      } else {
        callback();
      }
    };
    var validateType = (rule, value, callback) => {
      if (this.form.type == '') {
        callback(new Error('信息不全,无法添加'));
      } else {
        callback();
      }
    };
    var validateNameRoom = (rule, value, callback) => {
      if (!this.nameRoomArr[this.nameRoomArr.length-1]) {
        callback(new Error('信息不全,无法添加'));
      } else {
        callback();
      }
    };
    var validateNameFloor = (rule, value, callback) => {
      if(!this.editDetail.name){
        if (this.form.nameFloor == '') {
          callback(new Error('信息不全,无法添加'));
        } else {
          callback();
        }
      }else{
        if (this.form.name == '') {
          callback(new Error('信息不全,无法添加'));
        } else {
          callback();
        }
      }
    };
    return {
      // 获取row的key值
      getRowKeys(row) {
        return row.id;
      },
      expands: [],
      expandId: {
        id: null
      },
      idSave:  [],
      queryParams: {
        distId: '',
      },
      distAdress: '',
      dataTree: [],
      value: null,
      defaultProps: {
        label: 'distName'
      },
      dataTreeB: [],
      defaultPropsB: {
        children: "children",
        label: "name",
        value: "id",
      },
      rowEdit: {},
      nameRoomArr: [null],
      form:{
        level:'',
        name: '',
        distId: null,
        type: '',
        nameFloor: '',
        nameRoom: '',
      },
      editDetail: {
        name: '',
        type: '',
      },
      editDetailName: '',
      formRules: {
        distId: [ { required: true, message: "请选择校区", trigger: "blur" }, ],
        name: [ { validator: validateName, required: true, trigger: 'blur' } ],
        type: [ { validator: validateType, required: true, trigger: 'blur' } ],
        nameFloor: [ { validator: validateNameFloor, required: true, trigger: 'blur' } ],
        nameRoom: [ { validator: validateNameRoom, required: true, trigger: 'blur' } ],
      },

      pageApi: "membersDormitoryGetDormitoryTree",
      insertApi: "membersDormitoryAddDormitory",
      editorApi: "membersDormitoryEditDormitory",
      deleteApi: "membersDormitoryDeleteDormitory",
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("basicApartment", ["apartmentList", "apartmentTotal"]),
  },
  methods: {
    ...mapActions("basicCollege", [
      "membersSchoolDistAppUserDists",
    ]),
    ...mapActions("basicApartment", [
      "membersDormitoryGetDormitoryTree",
      "membersDormitoryAddDormitory",
      "membersDormitoryEditDormitory",
      "membersDormitoryDeleteDormitory",
      "membersDormitoryGetList",
      "membersDormitoryGetListD",
      "membersDormitoryGetDormitory",
      "membersDormitoryUserAdd",
      "membersDormitoryGetEdit",
      "membersSchoolGetSchoolDistTree",
      "membersSchoolDistLoad",
    ]),
    clickRowHandle(row, column, event) {  
      if (this.expands.includes(row.id+'')) {
				this.expands = this.expands.filter(val => val != row.id+'');
			} else {
				//判断是否已经存在展开的行
				if (this.expands.length != 0) {
				//如果存在展开行,清空expands数组,使它关闭
					this.expands.splice(0, this.expands.length);
				//打开点击的行
					this.expands.push(row.id+'');
				} else {
				//如果不存在展开行,直接push打开点击的行
					this.expands.push(row.id+'');
				}
			}
      // console.log(this.expands)
    },
    handleNodeClick(data) {
      this.form.distId = null
      if(data.length===4){ this.form.distId = data[data.length-1] }
      if(!this.form.distId){ return this.$message.warning("请选择校区") }
      this.$refs.cascader.dropDownVisible = false;
    },
    // 获取侧边栏区域树
    async areaTree(){
      this.dataTreeB = await this.membersSchoolGetSchoolDistTree({code: "area_cn"})
      // this.queryParams.schoolId = this.dataTreeB[0].id
    },
    handleClick(data){
      if((data.id+'').length === 9){ this.queryParams.province = data.id }
      if((data.id+'').length === 12){ this.queryParams.city = data.id }
      if((data.id+'').length === 15){ this.queryParams.area = data.id }
      if((data.id+'').length < 9){
        if(data.type == 5){ this.queryParams.distId =data.id
        } else{
          this.queryParams.schoolId =data.id
        }
      }
      this.refresh()
    },
    // 添加宿舍
    handleSketch() {
      this.nameRoomArr.forEach(item => {
        this.form.nameRoom = this.form.nameRoom + item + ','
      })
      this.form.nameRoom = this.form.nameRoom.substring(0, this.form.nameRoom.lastIndexOf(','))
    },
    addTeam() {
      if(this.nameRoomArr.length<100) {
        this.form.nameRoom = ''
        let length = this.nameRoomArr.length
        this.nameRoomArr = this.nameRoomArr.filter(item=>item);
        if( length == this.nameRoomArr.length+1 ){ this.$message.warning('请将信息填写完整,再添加') }
        this.nameRoomArr.push('');
      }else if(this.nameRoomArr.length>=100) {
        return this.$message({
          type: 'warning',
          message: '每次最多只能添加100个宿舍'
        });
      }
    },
    // 删除宿舍
    removeTeam(index) {
      this.nameRoomArr = this.nameRoomArr.filter((item, idx) => idx != index);
    },
    async afterHandleForm(row){
      this.expandId.id = row.id
      this.distIdRequire(row.distId)
      let res = await this.membersDormitoryGetEdit({level: row.level,id:row.id})
      // console.log(res)
      if(res.parent){
        this.form.name = res.parent.name
        this.form.distId = res.parent.distId
        this.form.type = res.parent.type
        this.form.nameFloor = res.children.name
        // console.log(this.form.type)
      }else{
        this.form.name = res.name
        this.form.distId = res.distId
        this.value = res.distId
        this.form.type = res.type
      }
      if(row.level==1){ this.form.level = 2 }
      if(row.level==2){ this.form.level = 3 }
      // console.log(this.dataTreeB)
      // for (var i = 0; i < this.dataTreeB.length; i++) {
      //   if (this.dataTreeB[i].department.length < 1) {
      //     // children若为空数组，则将children设为undefined
      //     this.dataTreeB[i].department = undefined;
      //   } else {
      //     this.getTreeData(data[i].department);
      //   }
      // }
    },
    async afterEditorForm(row) {
      this.distIdRequire(row.distId)
      // this.idSave = row.id
      let res = await this.membersDormitoryGetEdit({level: row.level,id:row.id})
      // console.log(res)
      this.form.id = row.id
      if(!!res.children){
        this.editDetail.name = res.parent.name
        this.editDetailName = res.parent.name
        this.editDetail.type = res.parent.type
        this.form.name = res.children.name
        this.form.level = res.children.level
      }else{
        this.form.name = res.name
        this.form.level = res.level
      }
      // console.log(this.editDetail)
    },
    // 校区id
    async distIdRequire(id){
      try{
        let res = await this.membersSchoolDistLoad({id})
        let extend = res.extend
        this.distAdress = extend.provinceName+'/'+extend.areaName+"/"+extend.schoolName+"/"+res.distName
      }catch(error){}
    },
    resetDetail(){
      this.distAdress = ''
      this.editDetail ={name:'', type:''}
      this.nameRoomArr = [ null ]
      if(this.aVisible == false){ this.value = null }
      // this.expands = this.editDetail.id
    },
    formChange(params){
      this.form.nameRoom = ''
      if(params.level>1){ this.clickRowHandle(this.expandId.id) }
      return true;
    },
    formEditChange(){ return true; },
  },
  async mounted() {
    this.dataTree = await this.membersSchoolDistAppUserDists({})
    // console.log(this.dataTree)
    if (this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name));
    this.refresh();
  },
  created(){
    this.areaTree()
  },
};
</script>
<style lang="scss" scoped>
.area-tree { background: #fff; margin-top: 15px; border-radius: 5px; margin-right: 10px; overflow: hidden; min-height: 550px;
  .hd { height:40px; border-bottom:#ddd 1px solid; line-height:40px; padding:0 10px; font-size:16px; }
}
.nameRoom {
  img { vertical-align: middle; margin-left: 20px; cursor: pointer; }
}
.w350 { width: 350px; }
.nothing{ width: 100%; height: 478px; position: relative; margin:0 auto;}
.nothing img { width: 330px;height: 170px; position: absolute; top:0;left:0;right:0;bottom:0;margin:auto;}
</style>
